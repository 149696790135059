import CodeEditor from "@cloudscape-design/components/code-editor";
import React, { useEffect, useState } from "react";

import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/xcode.css';
import 'ace-builds/css/theme/eclipse.css';
import { ButtonDropdown, Container, SpaceBetween } from "@cloudscape-design/components";

const i18nStrings = {
    loadingState: 'Loading code editor',
    errorState: 'There was an error loading the code editor.',
    errorStateRecovery: 'Retry',
  
    editorGroupAriaLabel: 'Code editor',
    statusBarGroupAriaLabel: 'Status bar',
  
    cursorPosition: (row: any, column: any) => `Ln ${row}, Col ${column}`,
    errorsTab: 'Errors',
    warningsTab: 'Warnings',
    preferencesButtonAriaLabel: 'Preferences',
  
    paneCloseButtonAriaLabel: 'Close',
  
    preferencesModalHeader: 'Preferences',
    preferencesModalCancel: 'Cancel',
    preferencesModalConfirm: 'Confirm',
    preferencesModalWrapLines: 'Wrap lines',
    preferencesModalTheme: 'Theme',
    preferencesModalLightThemes: 'Light themes',
    preferencesModalDarkThemes: 'Dark themes',
};

type Theme = 'xcode' | 'eclipse' | 'dawn'; // Add this type definition

const CodingScreen = () => {
    // use the url query, see if a language is specified
    // if so, set the language
    // if not, default to swift
    let _selectedLanguage = "swift";
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('language')) {
        _selectedLanguage = urlParams.get('language') as string;
    } else if (urlParams.has('lang')) {
        _selectedLanguage = urlParams.get('lang') as string;
    }
    const [selectedLanguage, setSelectedLanguage] = React.useState(_selectedLanguage);


    const [value, setValue] = useState('const pi = 3.14;');

    
    const [preferences, setPreferences] = useState<Partial<{ theme: Theme; wrapLines: boolean }>>({
        theme: 'xcode',
        wrapLines: true,
    });
    const [loading, setLoading] = React.useState(false);

    const [ace, setAce] = useState<any>(undefined);

    useEffect(() => {
        async function loadAce() {
            const ace = await import('ace-builds');
            await import('ace-builds/webpack-resolver');
            ace.config.set('useStrictCSP', true);

            return ace;
        }

        loadAce()
            .then(ace => setAce(ace))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Container footer={
            <div className="container-media-footer">
                <SpaceBetween direction="vertical" size="xs">
                    <ButtonDropdown 
                        items={[
                            { id: 'swift', text: 'Swift' },
                            { id: 'java', text: 'Java' },
                            { id: 'python', text: 'Python' },
                            { id: 'javascript', text: 'JavaScript' },
                            { id: 'typescript', text: 'TypeScript' },
                            { id: 'csharp', text: 'C#' },
                            { id: 'kotlin', text: 'Kotlin' },
                            { id: 'ruby', text: 'Ruby' },
                            { id: 'go', text: 'Go' },
                            { id: 'rust', text: 'Rust' },
                            { id: 'php', text: 'PHP' },
                            { id: 'sql', text: 'SQL' },
                            { id: 'html', text: 'HTML' },
                            { id: 'css', text: 'CSS' },
                            { id: 'json', text: 'JSON' },
                            { id: 'xml', text: 'XML' },
                            { id: 'yaml', text: 'YAML' },
                            { id: 'markdown', text: 'Markdown' },
                            { id: 'plaintext', text: 'Plain Text' },
                        ]}
                        // selectedId={selectedLanguage}
                        onItemClick={(e: any) => {
                            setSelectedLanguage(e.detail.id);
                        }}
                    >
                        Lang: {selectedLanguage}
                    </ButtonDropdown>
                </SpaceBetween>
            </div>
        }>
            {/* Main Contents in Container */}
            <div className="container-media-main">
                <h2>ChatSuSu.com Code Editor</h2>
                
                {loading ? (
                    <div>{i18nStrings.loadingState}</div>
                ) : ace ? (
                    <CodeEditor
                        language={selectedLanguage}
                        value={value}
                        onDelayedChange={event => setValue(event.detail.value)}
                        preferences={preferences}
                        onPreferencesChange={(e: any) => setPreferences(e.detail)}
                        loading={loading}
                        i18nStrings={i18nStrings}
                        themes={{ 
                            light: ['xcode', 'eclipse', 'dawn'], 
                            dark: [], 
                        }}
                        ace={ace}
                    />
                ) : (
                    <div>{i18nStrings.errorState}</div>
                )}
            </div>
            
        </Container>
            
        
    );
};

export default CodingScreen;
