/**
 * This AppVer2 is using streaming response, instead of poll 
 */

import React from 'react';
import './App.css';

import { Auth, API } from 'aws-amplify';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';

import { AppLayout, Button, Container, Input, Pagination, SpaceBetween, Spinner, Table, Tabs, TextContent, Textarea } from '@cloudscape-design/components';
import { useCollection,  } from '@cloudscape-design/collection-hooks';

import { NavContents } from './components/nav-contents';
import { getVariableFromCache, saveVariableToCache } from './utils/local-cache';
import { Tools } from './components/app-layout-tools';
import PerformanceLatency from './utils/perf-latency';
import { LocalAppConfig } from './components/local-app-config';
import AllDataManager from './utils/all-data-manager';

// TODO pull from remote app config
// update to local storage

/** AWS stuff */
// use Amplify without the stupid amplify cli overhead
// https://stackoverflow.com/questions/56455901/is-it-possible-to-use-the-amplify-framework-without-using-the-cli#:~:text=3%20Answers&text=I%20have%20learned%20that%20you,the%20amplify%20library%20as%20normal.&text=After%20that%20you%20need%20to,going%20to%20use%20with%20Amplify.

const amplifyConfig = {
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID, so confusing to find out what it is!!!
        // https://us-west-2.console.aws.amazon.com/cognito/pool/?region=us-west-2&id=us-west-2:fe0065c4-2cd6-443b-b2c6-af8442f99273
        identityPoolId: 'us-west-2:4ece5b83-7457-40bd-a74f-91063c04fd80',
        // REQUIRED - Amazon Cognito Region
        region: 'us-west-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-west-2_YAvfRF0tY',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '6dnmueujoolsincv6vqelq29i7',
    },
    API: {
        endpoints: [
            {
                name: "hosulxNetApiGW",
                endpoint: 'https://w462riqj3c.execute-api.us-west-2.amazonaws.com/prod',
                region: 'us-west-2',
                service: "execute-api",
            },
            {
                name: "TamCamEyAiEndpoint",
                endpoint: 'https://pt01rzrou9.execute-api.us-west-2.amazonaws.com/prod',
                region: 'us-west-2',
                service: "execute-api",
            }
        ]
    },
};

/**
 * Encapsulate a task/question from user, then the answer from LLM.
 */
export interface TaskResp {
    task: string;
    response: string;
    timestamp: string; // iso 8601

    // TODO
    // botCode: string;
    // botName: string;
    // botAvatarUrl: string;
}
// TODO use this for announcement. Pull from appConfig endpoint
const CHATSUSU_INTRO = '### ChatSuSu: Xin chào! Tôi là ChatSuSu, tôi có thể giúp gì cho bạn?';
const CSS_INFO_WAIT_FOR_EN_ANSWER = 'Đang suy nghĩ...';
const USER_INPUT_MAX_LENTH = 4096; // TODO use cached appConfig
const TIMEOUT_WAIT_FIRST_CHUNK = 15; // 15 tries

/**
 * Communication Codes from app
 * A: App
 * 
 * Example: 
 * A-I01 : App - bootstrapping app
 * @returns 
 */
function App() {

    // TODO read from url params
    // 1. /?tab=chat&task=what is the name of the world's highest waterfall?
    // then populate the chat panel with the task, so that it's convenient for the user to share the link

    // == History tab
    let [isLoadingHistory, setIsLoadingHistory] = React.useState<boolean>(false);
    let [tasks, setTasks] = React.useState([]);
    let [selectedTask, setSelectedTask] = React.useState<any>(null);
    let [isSpeakingTTS, setIsSpeakingTTS] = React.useState<boolean>(false); // this is for the speak button in History tab

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        tasks,
        {
            filtering: {
                noMatch: (
                    <span>Không có gì cả</span>
                ),
            },
            pagination: { pageSize: 2 }, // 2 items per page
            sorting: {},
            selection: {},
        }
    );

    // == Interactive chat - main
    let [usageToken, setUsageToken] = React.useState<string>(getVariableFromCache('usageToken') || '');
    let [aiServiceId, setAiServiceId] = React.useState<string>(getVariableFromCache('aiServiceId') || '');
    let [task, setTask] = React.useState<string>(getSuggestedQuestion());
    // cache the previousTask so we can re-use it conveniently for user, if user wants to re-ask the same question
    let [previousTask, setPreviousTask] = React.useState<string>(''); 
    // let [botCode, setBotCode] = React.useState('MTRL-07');
    let [isInteractingWithBot, setIsInteractingWithBot] = React.useState(false);
    let [partialAnswer, setPartialAnswer] = React.useState(CHATSUSU_INTRO);
    let [autoscroll, setAutoscroll] = React.useState<boolean>(false);
    let [isAutoSpeakEnabled, setIsAutoSpeakEnabled] = React.useState<boolean>(false);
    
    // == General state
    const [appIsActive, setAppIsActive] = React.useState(true); // TODO merge with remote appConfig
    const [activeTabId, setActiveTabId] = React.useState("chatsusu-tab");
    let [errorHappened, setErrorHappened] = React.useState(false);
    const WHATSGOINGON_DEFAULT = 'Bạn hỏi bằng tiếng Việt hay tiếng Anh cũng được. Xong nhấn nút "Go" nhé!';
    let [whatsGoingOn, setWhatsGoingOn] = React.useState<string>(WHATSGOINGON_DEFAULT);
    let [deviceType, setDeviceType] = React.useState<string>('desktop'); // ['desktop', 'mobile', 'tablet']


    // == State managament functions

    function resetAllStates() {
        setTask(getSuggestedQuestion());
        setPreviousTask('');
        // setBotCode('MTRL-07');
        setIsInteractingWithBot(false);
        setPartialAnswer(CHATSUSU_INTRO);
        setErrorHappened(false);
        setWhatsGoingOn(WHATSGOINGON_DEFAULT);
    }

    // == Main functions

    /**
     * Invoke the Lambda func URL endpoint with the userTask
     * render the response in the chat panel
     * @param task 
     */
    async function getStreamingAnswer(task: string) {
        // console.info('getStreamingAnswer...');
        const firstChunkAnswerLatency   = PerformanceLatency.start('css-webapp-firstChunkAnswer');
        const e2eAnswerLatency          = PerformanceLatency.start('css-webapp-getAnswerFromLLM');
        
        // prep control vars
        let chatSuSuBox = document.getElementById('chatsusu-box')!;
        let chatSuSuBoxTextareaEle = chatSuSuBox;
        // if _el is not textarea, traverse down the children
        if (chatSuSuBox.tagName.toLowerCase() !== 'textarea') {
            const children = chatSuSuBox.getElementsByTagName('textarea');
            if (children.length > 0) {
                chatSuSuBoxTextareaEle = children[0];
            } else {
                console.warn('Cannot find chatSuSuBox textarea element', chatSuSuBox);
            }

        }

        let cssInfoWaitForAnswer = CSS_INFO_WAIT_FOR_EN_ANSWER;
        setWhatsGoingOn(cssInfoWaitForAnswer);
        AllDataManager.rememberAskedSuggestedQuestion(task);

        // main flow
        try {
            // TODO validate input, and exit early if there are errors
            // if usage token is not set, exit early
            // if (!usageToken || usageToken.trim().length === 0) {
            //     console.debug('usageToken is empty, exit early');
            //     setWhatsGoingOn('Bạn chưa nhập mã sử dụng app -__-');
            //     return;
            // }

            // == Validation: 1. user input are empty 
            if (!task || task.trim().length === 0) {
                console.debug('task is empty, exit early');
                setWhatsGoingOn('Bạn chưa nhập câu hỏi nào cả -__-');
                return;
            }

            // == Validation: 2. user input are too long
            if (task.length > USER_INPUT_MAX_LENTH) {
                console.debug('task is too long, exit early');
                setWhatsGoingOn('Câu hỏi quá dài o_0, bạn vui lòng nhập lại nhé');
                return;
            }

            // == Validation: 3. user input are short
            if (task.length < 10) {
                console.debug('task is too short, exit early');
                setWhatsGoingOn('Câu hỏi ngắn quá, bạn có chắc không vậy?');
                // let it go through for now
                return;
            }

            // == Validation: 4. user input are too short
            if (task.length < 5) {
                console.debug('task is too short, exit early');
                setWhatsGoingOn('Câu hỏi quá ngắn, bạn vui lòng nhập lại nhé');
                return; // exit here
            }

            // happy path
            // === use HosulxCdkv2StackTamCamEyAiStack65F73C35.ChatFuncUrl = https://zxv7dlro22gcsw3tq6c3jcop5q0hfmgh.lambda-url.us-west-2.on.aws/
            const bodyPayload = JSON.stringify({
                userTask: task.trim(),
                billingKey: usageToken ? usageToken.trim().toUpperCase() : 'PK35',
                aiServiceId: aiServiceId ? aiServiceId.trim().toUpperCase() : 'AH30',
            });
            // console.log('fetching streaming endpoint with bodyPayload', bodyPayload);
            setIsInteractingWithBot(true);
            chatSuSuBoxTextareaEle.classList.add('chat-panel-thinking');
            const llmAnswerStreamingResp = await _fetchStreamingEndpoint(bodyPayload);

            // handle errors
            if (!llmAnswerStreamingResp.ok) {
                console.error('Failed to fetch...', llmAnswerStreamingResp);
                setErrorHappened(true);
                setIsInteractingWithBot(false);
                chatSuSuBoxTextareaEle.classList.remove('chat-panel-thinking');
                setWhatsGoingOn('Có lỗi xảy ra, bạn vui lòng thử lại nhé!');
                return;
            }

            // process the streaming response chunks
            // console.log('handling the resp stream', llmAnswerStreamingResp);

            if (!llmAnswerStreamingResp.body) {
                console.error('No body in the response', llmAnswerStreamingResp);
                setErrorHappened(true);
                setIsInteractingWithBot(false);
                chatSuSuBoxTextareaEle.classList.remove('chat-panel-thinking');
                setWhatsGoingOn('Có lỗi xảy ra, bạn vui lòng thử lại nhé!');
                return;
            }

            const reader = llmAnswerStreamingResp.body.getReader();
            let decoder = new TextDecoder();
            let _partialAnswer = '### You: ' + task + '\n\n' + '### ChatSuSu:\n';
            setPartialAnswer(_partialAnswer);
            let result = await reader.read();
            analyticsPerfLatency('firstChunkAnswer', firstChunkAnswerLatency.getLatencyMs(), 'chat_screen_v20:firstChunkAnswer', 'latency for asking a question and getting firstChunkAnswer from LLM');

            // normal path
            while (!result.done) {
                // ASSUMPTION: textChunk is a string
                const textChunk = decoder.decode(result.value, { stream: true });
                // console.debug('textChunk', textChunk);
                
                // update state to show the textChunks
                _partialAnswer = _partialAnswer + textChunk;
                setPartialAnswer(_partialAnswer);

                result = await reader.read();
            } // end while loop - rendering llm response chunks

            setWhatsGoingOn('Xong rồi đó. Muốn gì hỏi tiếp nhé.');
            setIsInteractingWithBot(false);
            chatSuSuBoxTextareaEle.classList.remove('chat-panel-thinking');

            // Analytics
            analyticsPerfLatency('getStreamingAnswer', e2eAnswerLatency.getLatencyMs(), 'chat_screen_v20:getStreamingAnswer', 'e2e latency for asking a question and getting an answer from LLM');

        } catch (error) {
            console.error('[ERROR] getStreamingAnswer', error);
        }
    }

    


    /**
     * Speak the text in English.
     * Text is always new.
     * @param text 
     */
    function speakText(text: string) {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'en-US'; // TODO one day 'vi-VN'
        utterance.rate = 1.0;
        utterance.onend = (event) => {
            setIsSpeakingTTS(false);
            console.log(`Utterance has finished being spoken after ${event.elapsedTime} seconds.`);
        };
        utterance.onerror = (event) => {
            setIsSpeakingTTS(false);
            console.error(`An error has occurred with the speech synthesis`, event);
            // console.error(`Additional information: ${event.message}`);
            setWhatsGoingOn('Có lỗi xảy ra khi đọc văn bản. Bạn vui lòng thử lại nhé!');
        }

        speechSynthesis.speak(utterance);
    }

    /**
     * Stop the currently speaking text
     */
    function stopSpeakingText() {
        // stop speaking
        speechSynthesis.cancel();
    }

    /**
     * Only support copy text of a textarea element for now.
     * @param textareaElementId id of the textarea element to copy from
     * @returns nothing
     */
    async function saveToClipboard(textareaElementId: string) {
        const isIos = navigator.userAgent.match(/ipad|iphone/i);
        // let textToCopy : string | undefined = undefined;
        const el = document.getElementById(textareaElementId)!;
        // test if el is a textarea
        let textarea : HTMLTextAreaElement | undefined = undefined;
        let textToCopy : string | undefined = undefined;
        if (el.tagName.toLowerCase() !== 'textarea') {
            console.warn('element is not a textarea, needs to traverse down the children DOM tree', el);
            
            // traserve down the DOM tree to find the textarea
            const children = el.getElementsByTagName('textarea');
            if (children.length > 0) {
                textarea = children[0];
            } else {
                console.error('Cannot find textarea element', el);
                return;
            }
        }
        textToCopy = el.textContent!;

        // ios hack
        if (isIos) {
            // select text
            const range = document.createRange();

            const selection = window.getSelection()!;
            selection.removeAllRanges();
            selection.addRange(range);
            textarea!.setSelectionRange(0, 999999);

            // copy selection
            document.execCommand('copy');
            return;
        }

        // other browsers/os combos: test if browser supports clipboard
        if (navigator.clipboard) {
            if (navigator.clipboard.writeText) {
                return await navigator.clipboard.writeText(textToCopy);
            }
        } else {
            return document.execCommand('copy', true, textToCopy);
        }
    }

    async function bootstrapApp() {
        // Set page title
        document.title = 'ChatSuSu.com - AI song ngữ Anh Việt của riêng bạn';
        
        // Set device type
        const isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
        const isTablet = window.matchMedia('only screen and (min-width: 769px) and (max-width: 1024px)').matches;
        const _deviceType = isMobile 
            ? 'mobile' 
            : (isTablet ? 'tablet' : 'desktop');
        setDeviceType(_deviceType);
        
        // Initialize Amplify
        Auth.configure(amplifyConfig);
        API.configure(amplifyConfig);
        
        try {
            await AllDataManager.initialize();
            setTask(getSuggestedQuestion());
        } catch (error) {
            console.error('[ERROR] bootstrapApp - failed', error);
        }

        // welcome user, and guide them to ask the first question
        setPartialAnswer(CHATSUSU_INTRO);
    }

    // === Helpers
    async function loadChatSuSuHistory() {
        const loadHistoryTabLatency = PerformanceLatency.start('css-webapp-loadHistoryTab');
        // === use tamcam endpoint from APIGateway
        // 1. listUserTasksByUserId
        let bodyPayload = {
            action: 'listUserTasksByUserId',
            billingKey: usageToken ? usageToken.trim().toUpperCase() : "PK35",
        };
        setIsLoadingHistory(true);
        const tasksWrapper = await API.post('TamCamEyAiEndpoint', '/tamcam/actions', {
            body: bodyPayload
        });
        setIsLoadingHistory(false);
        // handle errors 
        if (tasksWrapper.errors) {
            console.error('Fail to fetch... Error is', tasksWrapper.errors);
            setTasks([]);

            return;
        }

        // happy path
        // console.debug('Success - fetched tasks history', tasksWrapper);
        setTasks(tasksWrapper.tasks); // only display 5 latest tasks for now TODO add pagination

        // Analytics
        analyticsPerfLatency('loadHistoryTabLatency', loadHistoryTabLatency.getLatencyMs(), 'history_screen:loadHistory', 'loads chat history latency');
    }

    /**
     * Get a single task by taskId.
     * Use cases:
     * 1. Once LLM done answering a question in realtime, auto-update the final full response for user's convenience.
     * 
     * @param taskId 
     * @returns 
     */
    async function getUserTask(taskId: string) {
        // === use tamcam endpoint from APIGateway
        // 1. getUserTask
        let bodyPayload = {
            action: 'getUserTask',
            taskId: taskId,
        };
        const userTask = await API.post('TamCamEyAiEndpoint', '/tamcam/actions', {
            body: bodyPayload
        });

        // handle errors 
        if (userTask.errors) {
            console.error('Fail to getUserTask... Error is', userTask.errors);
            return;
        }

        // happy path
        // console.debug('Success - fetched userTask', userTask);
        return userTask;
    }

    // === Bootstrap
    React.useEffect(() => {
        // console.debug('[A-I01] Bootstrapping app...');

        const perfLatency = PerformanceLatency.start('css-webapp-bootstrap');
        // bootstrap
        bootstrapApp();
        
        // Analytics
        analyticsPerfLatency('bootstrap', perfLatency.getLatencyMs(), 'app_startup', 'app start up - bootstrapping required dependencies and remote resources');
    }, []);



    // === RENDERING
    // == edge cases
    if (!appIsActive) {
        return <div>
            <p>
                EN: ChatSuSu is currently not available. Please try again later.
            </p>
            <p>
                VI: ChatSuSu hiện tại không hoạt động. Bạn vui lòng thử lại sau nhé.
            </p>
        </div>;
    }

    // == happy path
    return (
        <AppLayout
            navigation={ <NavContents /> }
            navigationWidth={320}
            tools={  <Tools /> }
            
            maxContentWidth={ 'mobile' === deviceType ? 800 : 1024 }
            content={
                <Tabs
                    disableContentPaddings
                    onChange={async ({ detail }) => {
                        setActiveTabId(detail.activeTabId);
                        if (detail.activeTabId === 'history-tab') {
                            await loadChatSuSuHistory();
                        }
                    }}
                    activeTabId={activeTabId}
                    tabs={[
                        {
                            // === MAIN CHAT TAB
                            id: "chatsusu-tab",
                            label: i18n('tab.chatsusu.label'),

                            content: <div id='chat-main' >
                                <Container
                                    disableContentPaddings
                                    footer={
                                        <div className="container-media-footer">
                                            <SpaceBetween direction="vertical" size="xs">
                                                {/* What's going on */}
                                                <TextContent>
                                                    <small>
                                                        <img src={ require('./logo64.png' )} height={16} /> {whatsGoingOn}
                                                    </small>
                                                </TextContent>

                                                {/* User Task input */}
                                                <Textarea
                                                    onChange={({ detail }) => setTask(detail.value)}
                                                    value={task}
                                                    placeholder="Bạn gõ vào đây..."
                                                    rows={ 'mobile' === deviceType ? 3 : 12 }
                                                    spellcheck={false}
                                                    disabled={isInteractingWithBot}
                                                    autoFocus
                                                />

                                                {/* buttons */}
                                                <div style={{ textAlign: 'right' }}>
                                                    {/* Control Buttons */}
                                                    <div style={{display: 'inline-block', textAlign: 'right'}}>
                                                        <Button disabled={isInteractingWithBot} onClick={() => setTask('')} iconName='close'></Button>
                                                        
                                                        <div style={{width: '12px', display: 'inline-block'}}></div>
                                                        <Button disabled={isInteractingWithBot} iconName='suggestions' onClick={() => setTask(getSuggestedQuestion()) }></Button>
                                                        
                                                        <div style={{width: '12px', display: 'inline-block'}}></div>
                                                        <Button disabled={isInteractingWithBot} iconName='undo' onClick={() => setTask(previousTask) }></Button>
                                                        
                                                        <div style={{width: '12px', display: 'inline-block'}}></div>
                                                        {/* Go button */}
                                                        <Button disabled={isInteractingWithBot} onClick={async () => {
                                                            // if use set the usageToken, save it for convience
                                                            if (usageToken.trim().length > 0) {
                                                                saveVariableToCache('usageToken', usageToken);
                                                            }
                                                            // if use set the aiServiceId, save it for convience
                                                            if (aiServiceId.trim().length > 0) {
                                                                saveVariableToCache('aiServiceId', aiServiceId);
                                                            }
                                                            // call the main function
                                                            await getStreamingAnswer(task);
                                                            // console.error('see me?');
                                                        }}>
                                                            Go
                                                        </Button>
                                                    </div>

                                                </div>
                                            </SpaceBetween>
                                        </div>
                                    }>
                                    
                                    {/* Chat Panel - textarea readonly*/}
                                    <Textarea id='chatsusu-box' rows={ 'mobile' === deviceType ? 12 : 24 } value={partialAnswer} autoComplete={false} disableBrowserAutocorrect readOnly />
                                    <div style={{padding: '8px', textAlign: 'right'}}>
                                        { isInteractingWithBot 
                                            ? 
                                            <>
                                                <Spinner size="big" />
                                            </>
                                            : 
                                            <>
                                                <span className='chatsusu-disclaimer'><small>Lưu ý: ChatSuSu có khi sai nha&nbsp;&nbsp;</small></span> 
                                                <Button iconName='copy' onClick={() => saveToClipboard('chatsusu-box') }>Copy</Button>
                                            </>
                                        }
                                        
                                        
                                    </div>
                                </Container>
                            </div>
                        },
                        {
                            // === HISTORY TAB
                            label: i18n('tab.history.label'),
                            id: "history-tab",
                            content: <>
                                {/* Tasks table */}
                                <Table
                                    columnDefinitions={HISTORY_COL_DEF}
                                    wrapLines
                                    items={items}
                                    loadingText="Đang tải..."
                                    empty={i18n('tab.history.table.empty')}
                                    stickyHeader
                                    variant="container"
                                    onRowClick={({ detail }) => {
                                        // console.debug('selected task', detail.item);
                                        setSelectedTask(detail.item);
                                    }}
                                    pagination={
                                        <Pagination {...paginationProps} />
                                    }
                                    filter={
                                        // use this space for the table Control Buttons
                                        isLoadingHistory 
                                            ? <Spinner size="big" />
                                            : <Button iconName='refresh' onClick={async () => await loadChatSuSuHistory() }/>
                                    }
                                />

                                <div style={{height: '8px'}}></div>

                                {/* Details */}
                                {selectedTask &&
                                <Container>
                                    <div style={{padding: '0 0 8px 0', textAlign: 'right'}}>
                                        {isSpeakingTTS 
                                            ? <Button iconName='close' onClick={() => { setIsSpeakingTTS(false); stopSpeakingText(); } }>{i18n('tab.history.tts.stop')}</Button>
                                            : <Button iconName='audio-full' onClick={() => { setIsSpeakingTTS(true); speakText(selectedTask.fullResponse); } }>{i18n('tab.history.tts.speak')}</Button>
                                        }
                                        

                                        <div style={{width: '8px', display: 'inline-block'}}></div>
                                        <Button iconName='copy' onClick={() => saveToClipboard('chatsusu-history-task-details')}>
                                            Copy
                                        </Button>

                                        <div style={{width: '8px', display: 'inline-block'}}></div>
                                        <Button iconName='undo' onClick={() => setTask(selectedTask.task) }>
                                            Again
                                        </Button>
                                    </div>
                                    <Textarea id="chatsusu-history-task-details" readOnly rows={14} value={selectedTask 
                                        ? `### You: ${selectedTask.task}\n\n### ChatSuSu:\n${selectedTask.fullResponse ?? ''}` 
                                        : ''}/>
                                </Container>
                                }
                            </>
                        },
                        {
                            // === SETTINGS TAB
                            label: i18n('tab.settings.label'),
                            id: "settings-tab",
                            content: (<Container>
                                <SpaceBetween direction='vertical' size='xs'>
                                    {/* Usage Token Input / Billing Key */}
                                    <div style={{display: 'inline-block', paddingRight: '8px', width: '12em'}}>
                                        <Input  ariaLabel='Mã sử dụng' id='usage-token-input' value={usageToken} type="text" onChange={({ detail }) => setUsageToken(detail.value.toUpperCase())} placeholder='Mã sử dụng app...' disabled={isInteractingWithBot} />
                                    </div>
                                    <div style={{display: 'inline-block', paddingRight: '8px', width: '12em'}}>
                                        <Input  ariaLabel='Mã dịch vụ AI' id='ai-service-input' value={aiServiceId} type="text" onChange={({ detail }) => setAiServiceId(detail.value.toUpperCase())} placeholder='Mã dịch vụ AI...' disabled={isInteractingWithBot} />
                                    </div>
                                    <Button onClick={() => resetAllStates()}>Reset | Cài Lại</Button>
                                    { !autoscroll 
                                        ? <Button onClick={() => { setAutoscroll(true) }}>Auto-scroll | Bật Tự động theo khi ChatSuSu trả lời</Button> 
                                        : <Button onClick={() => { setAutoscroll(false) }}>Auto-scroll | Tắt Tự động theo khi ChatSuSu trả lời</Button> 
                                    }

                                    { isAutoSpeakEnabled
                                        ? <Button onClick={() => { setIsAutoSpeakEnabled(false); stopSpeakingText();  } }>Auto-speak | Tắt Tự động Nói khi ChatSuSu trả lời</Button>
                                        : <Button onClick={() => { setIsAutoSpeakEnabled(true) } }>Auto-speak | Bật Tự động Nói khi ChatSuSu trả lời</Button>}

                                    {/* TODO set chat panel rows  */}
                                    {/* <Button disabled={true} onClick={() => {}}>Set user name</Button>
                                    <Button disabled={true} onClick={() => {}}>Erase history</Button>
                                    <Button disabled={true} onClick={() => {}}>Change font size</Button>
                                    <Button disabled={true} onClick={() => {}}>Switch theme</Button> */}
                                </SpaceBetween>

                                <div id="copy-clipboard-ios" />
                            </Container>)
                        },
                        // TODO ANNOUNCEMENT TAB
                    ]}
                    variant="container"
                />
            }

        ></AppLayout>
    );
}

export default App;

// === Helpers
function i18n(key: string, langCode = 'vn'): string {
    const enMapping: any = {
        'navigation.settings': 'Settings',
    };
    const viMapping: any = {
        'navigation.settings': 'Settings',
        'tab.chatsusu.label': 'ChatSuSu',
        'tab.history.label': 'Lịch sử',
        'tab.settings.label': 'Cài đặt',

        'tab.history.table.empty': 'Không có gì cả',
        'tab.history.tts.stop': 'Stop',
        'tab.history.tts.speak': 'Đọc',
    };
    return viMapping[key] || 'undefined';
}

const HISTORY_COL_DEF = [
    {
        id: "task",
        header: "Task",
        cell: (item: any) => shortenText(item.task, 81),
    },
];

/**
 * Returns full text if it's shorter than maxLength.
 * Otherwise, returns the first maxLength characters + '...'
 * @param text 
 * @param maxLength 
 */
function shortenText(text: string, maxLength: number = 64) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
}

/**
 * Pull a random question from the AllDataManager getSuggestedQuestion, that has not been asked before.
 * @returns 
 */
function getSuggestedQuestion() {
    return AllDataManager.getSuggestedQuestion();
}

/**
 * Extract the EN part from the localPartialAnswer.
 * 
 * Example MixedText is => "[### You: What is the name of the world's highest waterfall? ### ChatSuSu: ==EN: The world's highest waterfall is Angel Falls, which is located in Venezuela and stands at a height of 979 meters (3,212 feet) tall. ==VI: ]"
 * 
 * Returns: "The Sahara desert is located in Northern Africa, covering an area of approximately 3.6 million square miles. It is the largest hot desert in the world and is known for its vast sand dunes, arid landscape, and unique flora and fauna."
 * If no EN part is found, returns empty string.
 */
function extractEnglishFromMixedText(mixedText: string) : string {
    // errors handling
    if (!mixedText) {
        console.error('mixedText is empty, exit early');
        return '';
    }

    let cleaningText = mixedText.split('==EN:');
    if (cleaningText.length < 2) {
        console.error('mixedText is missing ==EN: marker, exit early');
        return '';
    }
    cleaningText = cleaningText[1].split('==VI:');
    if (cleaningText.length < 2) {
        console.error('mixedText is missing ==VI: marker, exit early');
        return '';
    }
    
    // happy path
    // let enAnswer = mixedText.split('==EN:')[1].split('==VI:')[0];
    // console.debug(`[I-B01] enAnswer is [${enAnswer.trim()}]`);
    return cleaningText[0].trim();
}

/**
 * Report performance latency to analytics backend.
 * @param actionEvent 
 * @param value
 * @param breadcrumb
 * @param friendlyName
 */
function analyticsPerfLatency(actionEvent: string, value: number, breadcrumb: string, friendlyName?: string) {
    // example payload
    // {"app":"chatsusu_web", "version":"version_unittest",  "action": "analyticsEvent", "actionEvent":"firstChunkLatency", "unit": "ms", "value": 5250, "breadcrumb":"<domain_name>:chat_screen_v20:start_convo", "friendlyName": "First Chunk Latency from start asking question to first chunk of answer from LLM"}

    // endpoint is /tamcam/actions
    const lantecyUnit = "ms";
    let bodyPayload = { 
        "app": `${LocalAppConfig.appName}_${LocalAppConfig.platform}`, 
        "version": `${LocalAppConfig.version}`, 
        "action": "analyticsEvent", 
        "actionEvent": actionEvent, 
        "unit": lantecyUnit, 
        "value": value, 
        "breadcrumb": breadcrumb, 
        "friendlyName": friendlyName, 
        "additionalData": `latency=[${value}];unit=[${lantecyUnit}];friendlyName=[${friendlyName}]`,
    };

    try {
        const resp = API.post('TamCamEyAiEndpoint', '/tamcam/actions', {
            body: bodyPayload
        });
    } catch (error) {
        console.debug('[ERROR] failed to report analyticsPerfLatency', error);
    }
}

/**
     * Helper function to fetch the streaming endpoint
     * @param bodyPayload 
     * @returns 
     */
async function _fetchStreamingEndpoint(bodyPayload: any) {
    const url = new URL('https://zxv7dlro22gcsw3tq6c3jcop5q0hfmgh.lambda-url.us-west-2.on.aws');
    const currentAuthCreds = await Auth.currentCredentials();
    const identityId = currentAuthCreds.identityId;

    const request = new HttpRequest({
        hostname: url.hostname,
        path: url.pathname,
        // url: new URL(url),
        method: 'POST',
        body: bodyPayload,
        headers: {
            'Content-Type': 'application/json',
            host: url.hostname,
            cognitoidentityid: identityId,
        }
    });
    
    const signer = new SignatureV4({
        service: 'lambda',
        credentials: currentAuthCreds,
        region: 'us-west-2',
        sha256: Sha256,
    });

    const { headers, body, method } = await signer.sign(request);
    const streamingResponse = await fetch(url, {
        method,
        headers,
        body,
        mode: "cors",
        cache: "no-cache",
    });

    return streamingResponse;
}